<template>
  <div class="rounded shadow-sm p-3 bg-color-secondary d-flex">
    <div class="my-auto">
      <img :src="product.imageUrl" class="product-image" />
    </div>
    <div class="ml-2 w-100">
      <div class="d-flex">
        <div class="line-clamp">{{ product.name }}</div>
        <div class="d-flex ml-auto">
          <div class="">
            <font-awesome-icon
              v-if="orderStatus !== 1"
              class="text-color-secondary"
              icon="minus-circle"
              size="lg"
            />
            <font-awesome-icon
              v-if="orderStatus === 1"
              @click="removeProductQuantity(product)"
              icon="minus-circle"
              size="lg"
            />
          </div>
          <div class="mx-2 f-size-16 ">{{ product.quantity }}</div>
          <div class="">
            <font-awesome-icon
              v-if="orderStatus !== 1"
              class="text-color-secondary"
              icon="plus-circle"
              size="lg"
            />
            <font-awesome-icon
              v-if="orderStatus === 1"
              @click="addProductQuantity(product)"
              icon="plus-circle"
              size="lg"
            />
          </div>
        </div>
      </div>

      <div class="f-size-14 text-secondary">
        {{ product.barcode }}
      </div>

      <div class="d-flex">
        <div class="f-size-14 text-secondary">
          {{ product.price | numeral("0,0.00") }} x {{ product.quantity }}
        </div>
        <div class="ml-auto f-size-14 ">
          <template v-if="product.discountStikePrice !== 0">
            <del class="text-color-grey">
              {{ product.total | numeral("0,0.00") }}
            </del>
            <span class="ml-2">
              {{ product.stikePrice | numeral("0,0.00") }}
            </span>
          </template>
          <template v-else>
            {{ product.total | numeral("0,0.00") }}
          </template>
        </div>
      </div>

      <div
        class="mt-3 f-size-14 text-secondary"
        v-if="product.displayPromotions && product.displayPromotions.length > 0"
      >
        <div>
          <div class="">โปรโมชั่น</div>
        </div>
        <div class="mt-2">
          <div
            v-for="(promotion, index) in product.displayPromotions"
            :key="index"
          >
            <div class="text-small  text-left pl-1">
              <div>{{ index + 1 }}. {{ promotion.name }}</div>
              <div>
                ({{ promotion.startDate | moment($formatDate) }} -
                {{ promotion.endDate | moment($formatDate) }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <b-row v-if="product.imageUrl !== ''">
      <b-col cols="2" xl="2" md="2" class="align-self-center d-none d-sm-block">
        <img style="width:100%" :src="product.imageUrl" alt />
      </b-col>
      <b-col cols="12" xl="10" md="10" class="">
        <b-row class="">
          <b-col cols="2" xl="2" class="d-block d-sm-none px-1 pt-2">
            <img style="width:100%" :src="product.imageUrl" alt />
          </b-col>
          <b-col cols="6" xl="9" md="8" class="p-0 pl-1">
            <div class="mr-2">{{ product.name }}</div>
            <div class="mr-2 text-color-grey">{{ product.barcode }}</div>

            <div class="text-color-grey d-block d-sm-none">
              {{ product.price | numeral("0,0.00") }} x
              {{ product.quantity | numeral("0,0.00") }}
            </div>
          </b-col>
          <b-col
            cols="4"
            xl="3"
            md="4"
            class="d-flex align-items-end flex-column"
            style="padding-left:0px"
          >
            <div>
              <font-awesome-icon
                v-if="orderStatus !== 1"
                class="text-color-secondary"
                icon="minus-circle"
                size="lg"
              />
              <font-awesome-icon
                v-if="orderStatus === 1"
                @click="removeProductQuantity(product)"
                icon="minus-circle"
                size="lg"
              />
              <span class="mx-2 text-big" style="vertical-align:middle">{{
                product.quantity
              }}</span>
              <font-awesome-icon
                v-if="orderStatus !== 1"
                class="text-color-secondary"
                icon="plus-circle"
                size="lg"
              />
              <font-awesome-icon
                v-if="orderStatus === 1"
                @click="addProductQuantity(product)"
                icon="plus-circle"
                size="lg"
              />
            </div>

            <div
              class="text-right mt-auto d-block d-sm-none"
              v-if="product.discountStikePrice !== 0"
            >
              <del class="text-color-grey">
                {{ product.total | numeral("0,0.00") }}
              </del>
              <span class="ml-2">
                {{ product.stikePrice | numeral("0,0.00") }}
              </span>
            </div>
            <div class="text-right mt-auto d-block d-sm-none" v-else>
              {{ product.total | numeral("0,0.00") }}
            </div>
          </b-col>
        </b-row>

        <b-row class="">
          <b-col class="d-none d-sm-block p-0 pl-1">
            <div class="text-color-grey">
              ราคา {{ product.price | numeral("0,0.00") }} x
              {{ product.quantity | numeral("0,0.00") }}
            </div></b-col
          >
          <b-col
            class="d-none d-sm-block"
            v-if="product.discountStikePrice !== 0"
            ><div class="text-right">
              <del class="text-color-grey">
                {{ product.total | numeral("0,0.00") }}
              </del>
              <span class="ml-2">
                {{ product.stikePrice | numeral("0,0.00") }}
              </span>
            </div>
          </b-col>
          <b-col class="d-none d-sm-block" v-else
            ><div class="text-right">
              {{ product.total | numeral("0,0.00") }}
            </div>
          </b-col>
        </b-row>

        <div
          v-if="product.discountStikePrice !== 0"
          class="mt-1 d-none d-sm-block"
        >
          <b-row>
            <b-col class="text-small text-color-grey text-left pl-1">
              <div>โปรโมชั่น</div>
            </b-col>
          </b-row>
          <b-row
            v-for="(promotion, index) in product.displayPromotions"
            :key="index"
          >
            <b-col class="text-small text-color-grey  text-left pl-1">
              <div>{{ index + 1 }}. {{ promotion.name }}</div>
              <div>
                ({{ promotion.startDate | moment($formatDate) }} -
                {{ promotion.endDate | moment($formatDate) }})
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="product.discountStikePrice !== 0"
          class="mt-1 d-block d-sm-none ml-5"
        >
          <b-row>
            <b-col class="text-small text-color-grey text-left pl-1">
              <div>โปรโมชั่น</div>
            </b-col>
          </b-row>
          <b-row
            v-for="(promotion, index) in product.displayPromotions"
            :key="index"
          >
            <b-col class="text-small text-color-grey  text-left pl-1">
              {{ index + 1 }}. {{ promotion.name }} (
              {{ promotion.startDate | moment($formatDate) }} -
              {{ promotion.endDate | moment($formatDate) }} )
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12" class="pl-4">
        <b-row class="">
          <b-col cols="7" xl="9" md="8" class="p-0 pl-1">
            <div class="mr-2">{{ product.name }}</div>
            <div class="mr-2 text-color-grey">{{ product.barcode }}</div>

            <div class="text-color-grey d-block d-sm-none">
              {{ product.price | numeral("0,0.00") }} x
              {{ product.quantity | numeral("0,0.00") }}
            </div>
          </b-col>
          <b-col
            cols="5"
            xl="3"
            md="4"
            class="d-flex align-items-end flex-column"
            style="padding-left:0px"
          >
            <div>
              <font-awesome-icon
                v-if="orderStatus !== 1"
                class="text-color-secondary"
                icon="minus-circle"
                size="lg"
              />
              <font-awesome-icon
                v-if="orderStatus === 1"
                @click="removeProductQuantity(product)"
                icon="minus-circle"
                size="lg"
              />
              <span class="mx-2 text-big" style="vertical-align:middle">{{
                product.quantity
              }}</span>
              <font-awesome-icon
                v-if="orderStatus !== 1"
                class="text-color-secondary"
                icon="plus-circle"
                size="lg"
              />
              <font-awesome-icon
                v-if="orderStatus === 1"
                @click="addProductQuantity(product)"
                icon="plus-circle"
                size="lg"
              />
            </div>

            <div
              class="text-right mt-auto d-block d-sm-none"
              v-if="product.discountStikePrice !== 0"
            >
              <del class="text-color-grey">
                {{ product.total | numeral("0,0.00") }}
              </del>
              <span class="ml-2">
                {{ product.stikePrice | numeral("0,0.00") }}
              </span>
            </div>
            <div class="text-right mt-auto d-block d-sm-none" v-else>
              {{ product.total | numeral("0,0.00") }}
            </div>
          </b-col>
        </b-row>

        <b-row class="">
          <b-col class="d-none d-sm-block p-0 pl-1">
            <div class="text-color-grey">
              ราคา {{ product.price | numeral("0,0.00") }} x
              {{ product.quantity | numeral("0,0.00") }}
            </div></b-col
          >
          <b-col
            class="d-none d-sm-block"
            v-if="product.discountStikePrice !== 0"
            ><div class="text-right">
              <del class="text-color-grey">
                {{ product.total | numeral("0,0.00") }}
              </del>
              <span class="ml-2">
                {{ product.stikePrice | numeral("0,0.00") }}
              </span>
            </div>
          </b-col>
          <b-col class="d-none d-sm-block" v-else
            ><div class="text-right">
              {{ product.total | numeral("0,0.00") }}
            </div>
          </b-col>
        </b-row>
        <div v-if="product.discountStikePrice !== 0" class="mt-1">
          <b-row>
            <b-col class="text-small text-color-grey text-left pl-1">
              <div>โปรโมชั่น</div>
            </b-col>
          </b-row>
          <b-row
            v-for="(promotion, index) in product.displayPromotions"
            :key="index"
          >
            <b-col class="text-small text-color-grey  text-left pl-1">
              <div>{{ index + 1 }}. {{ promotion.name }}</div>
              <div>
                ({{ promotion.startDate | moment($formatDate) }} -
                {{ promotion.endDate | moment($formatDate) }})
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row> -->
  </div>
</template>
<script>
export default {
  props: {
    orderStatus: Number,
    branchId: Number,
    product: Object,
    addProductQuantity: Function,
    removeProductQuantity: Function
  },
  methods: {
    addCommas(x = 0) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00";
    }
  }
};
</script>

<style lang="scss" scoped>
.product-image {
  height: 80px;
  width: 80px;
  object-fit: contain;
  border: 1px solid #e8e7e7;
  background-color: white;
}
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.f-size-16 {
  font-size: 16px;
}
</style>
