<template>
  <div>
    <div v-if="isMobile" class="card-box-header">
      <div class="d-flex bd-highlight">
        <div class="img_back mr-3" @click="onBackToOrderListPage()">
          <font-awesome-icon
            :icon="['fas', 'chevron-left']"
            class="img_back_icon"
          />
        </div>
      </div>
    </div>
    <div class="panel-overflow">
      <div
        class="d-flex mx-3 mt-3 color-primary"
        style="border-left: 5px solid"
      >
        <div class="ml-2">
          <div class="text-big font-weight-bold text-black">รายการขาย</div>
          <div class="text-small text-secondary">
            {{ orderSelected.invoiceNo }}
          </div>
          <div class="d-flex">
            <span
              class="ml-2 pointer text-black"
              v-if="orderSelected.canDownloadJobSheet"
              @click="onDownloadJobsheet()"
            >
              <font-awesome-icon class="cmg-icon" icon="file-download" />
            </span>
            <span
              class="pointer ml-2 text-black"
              v-if="orderSelected.canUpdateTracking"
              @click="setShowModalUpdateTracking(true)"
            >
              <font-awesome-icon class="cmg-icon" icon="truck"
            /></span>
          </div>
        </div>
        <div class="ml-auto my-auto">
          <OrderStatus
            v-if="forceRefresh"
            :orderSelected="orderSelected"
            :onSelectedStatus="onSelectedStatus"
          />
        </div>
      </div>
      <div class="d-flex mt-3 mx-3">
        <div class="my-auto">
          <span>เลือกช่องทางการขาย</span>
        </div>
        <div class="ml-auto">
          <b-form-select
            v-model="orderSelected.saleChannel"
            :options="saleChannelLists"
            id="saleChannel"
            :class="[{ error: $v.orderSelected.$error }]"
            :disabled="orderSelected.statusId !== 1"
            value-field="id"
            text-field="name"
            disabled-field="notEnabled"
            ref="saleChannel"
            @change="onSaleChannelSelected(orderSelected.saleChannel)"
          >
            <template v-slot:first>
              <b-form-select-option value="" disabled
                >-- กรุณาเลือกช่องทางการขาย --</b-form-select-option
              >
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="pr-3 text-right text-danger" v-if="$v.orderSelected.$error">
        *กรุณาเลือกช่องทางการขาย
      </div>
      <div class="d-flex mt-2 mx-3">
        <div class="my-auto">
          <span>เลือกสาขา </span>
        </div>
        <div class="ml-auto">
          <b-form-select
            v-if="isStore === 1"
            v-model="orderSelected.branchId"
            :options="branchLists"
            class=""
            :disabled="orderSelected.statusId !== 1"
            value-field="id"
            text-field="name"
            disabled-field="notEnabled"
            ref="branch"
            @change="onBranchSelected(orderSelected.branchId)"
          >
            <template v-slot:first>
              <b-form-select-option value="" disabled
                >-- กรุณาเลือกสาขา --</b-form-select-option
              >
            </template>
          </b-form-select>
          <b-form-select v-model="defaultVal" :disabled="true" v-else>
            <template v-slot:first>
              <b-form-select-option value=""
                >---------- DC ---------</b-form-select-option
              >
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="d-flex mt-3 mx-3">
        <div class="my-auto">
          <span class="font-weight-bold">รายการสินค้า</span>
        </div>
        <div
          @click="setShowModalProducts(true)"
          :class="[
            'ml-auto',
            { 'text-secondary pointer': orderSelected.statusId !== 1 }
          ]"
        >
          <span><u class="pointer">เพิ่ม/ค้นหา</u></span>
        </div>
      </div>
      <div class="mt-3 mx-3" v-if="orderSelected.products.length == 0">
        <div @click="setShowModalProducts(true)">
          <div
            class="pointer bg-color-secondary rounded shadow-sm text-center p-5"
          >
            <span class="text-color-secondary"
              >คลิ๊กเพื่อเพิ่มสินค้าลงตะกร้า</span
            >
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="my-2 mx-3"
          v-for="(item, index) in orderSelected.products"
          :key="index + 'product'"
        >
          <SelectedProduct
            :orderStatus="orderSelected.statusId"
            :product="item"
            :branchId="orderSelected.branchId"
            :addProductQuantity="addProductQuantity"
            :removeProductQuantity="removeProductQuantity"
          />
        </div>
      </div>
      <div class="d-flex mt-3 mx-3">
        <div class="my-auto">
          <span class="font-weight-bold">รายการของแถม</span>
        </div>
      </div>
      <div class="mt-2 mx-3 mb-3" v-if="orderSelected.freeProducts.length == 0">
        <div>
          <div
            class="pointer bg-color-secondary rounded shadow-sm text-center p-5"
          >
            <span class="text-color-secondary">ไม่มีรายการของแถม</span>
          </div>
        </div>
      </div>
      <div v-else class="mx-3 mb-4">
        <FreeProduct
          :freeProducts="orderSelected.freeProducts"
          :isEdit="orderSelected.statusId == 1"
          v-on:onSubmitFreeProduct="onSubmitFreeProduct"
          ref="freeProduct"
        />
      </div>
      <div v-if="isMobile" class="mb-4">
        <OrderUserDetail
          :orderSelected="orderSelected"
          :getOrderInformationById="getOrderInformationById"
          :isMobile="isMobile"
        />
      </div>
      <div class="mx-3">
        <OrderSummary
          :orderSelected="orderSelected"
          :isUseCoupon="isUseCoupon"
          v-on:onCalculatedPrice="onCalculatedPrice"
        />
      </div>
      <div class="custom-margin"></div>
    </div>

    <div class="fixed-footer shadow pt-2 pb-2 pl-3 pr-3">
      <div>
        <div class="mb-3">
          <div class="d-flex font-weight-bold f-size-18">
            <div><span class="">ราคาสุทธิ</span></div>
            <div class="ml-auto">
              <span class="">{{
                orderSelected.grandTotal | numeral("0,0.00")
              }}</span>
            </div>
          </div>
        </div>
        <div class="center">
          <button
            class="btn w-60 rounded-pill text-white bg-color-primary"
            @click="onSubmitedOrder()"
            :disabled="
              orderSelected.products.length === 0 ||
                isDisableSubmitButton ||
                orderSelected.saleChannel === ''
            "
          >
            <span
              v-if="
                orderSelected.statusId == 1 &&
                  [0].includes(orderSelected.saleChannel)
              "
              >ยืนยันและคัดลอกลิ้งค์</span
            >
            <span
              v-else-if="
                [1, 2, 3].includes(orderSelected.saleChannel) &&
                  orderSelected.statusId == 1
              "
            >
              ยืนยันและส่งลิ้งค์
            </span>
            <span v-else>แก้ไขและคัดลอกลิ้งค์</span>
          </button>
        </div>
      </div>
    </div>
    <template>
      <ModalAddProduct
        v-if="forceRefresh"
        v-bind:isShow="isShowModalProduct"
        :brandSelected="orderSelected.brandId"
        :branchSelected="orderSelected.branchId"
        :productDiscounts="orderSelected.productDiscount"
        v-on:setShowModalProducts="setShowModalProducts"
        :addProductToLists="addProductToLists"
      />
      <ModalCancelOrder
        v-if="forceRefresh"
        :isShow="isShowModalCancel"
        :note="orderSelected.note"
        :orderId="orderSelected.id"
        v-on:setShowModalCancelOrder="setShowModalCancelOrder"
        v-on:onSubmitedRestock="onSubmitedRestock"
      />
      <ModalUpdateTracking
        v-if="forceRefresh"
        v-bind:isShow="isShowModalTracking"
        v-bind:orderSelected="orderSelected"
        v-bind:updateTrackingNo="updateTrackingNo"
        v-on:setShowModalUpdateTracking="setShowModalUpdateTracking"
      />
      <ModalMessage ref="modalMessage" :message="message" />
      <ModalCopyLink
        ref="modalCopyLink"
        :urlLink="urlLink"
        :remarkMessage="remarkMessage"
      />
      <input id="testing-code" type="hidden" :value="urlLink" />
    </template>
  </div>
</template>

<script>
import OrderStatus from "@/components/order/component/OrderStatus";
import SelectedProduct from "@/components/SelectedProduct";
import FreeProduct from "@/components/order/component/FreeProduct";
import ModalAddProduct from "@/components/modal/ModalAddProduct";
import ModalMessage from "@/components/modal/ModalMessage";
import ModalCopyLink from "@/components/modal/ModalCopyLink";
import ModalCancelOrder from "@/components/modal/ModalCancelOrder";
import ModalUpdateTracking from "@/components/modal/ModalUpdateTracking";
import OrderUserDetail from "@/components/order/OrderUserDetail";
import { mapGetters } from "vuex";
import OrderSummary from "./component/OrderSummary.vue";
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    orderSelected: {
      required: true,
      type: Object
    },
    getOrderInformationById: {
      required: true,
      type: Function
    },
    isMobile: {
      required: true,
      type: Boolean
    }
  },
  components: {
    OrderStatus,
    ModalAddProduct,
    SelectedProduct,
    FreeProduct,
    ModalMessage,
    ModalCopyLink,
    ModalCancelOrder,
    ModalUpdateTracking,
    OrderUserDetail,
    OrderSummary
  },
  data() {
    return {
      defaultVal: null,
      forceRefresh: true,
      saleChannelLists: [],
      branchLists: [],
      isShowModalProduct: false,
      freeProducts: [],
      message: "",
      changeStatusFlag: false,
      urlLink: "",
      isShowModalCancel: false,
      isShowModalTracking: false,
      remarkMessage: "",
      isUseCoupon: false
    };
  },
  validations: {
    orderSelected: {
      saleChannel: { required }
    }
  },
  created: async function() {
    await this.getSocialMediaSalesChanel();
    await this.getAllBranch();
    await this.getFreeProducts();
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      isStore: "getStoreFlag",
      isShowModalOverLay: "getIsLoading",
      mainColor: "getBrandColor",
      isDisableSubmitButton: "getIsDisableSubmitButton"
    })
  },
  methods: {
    // checkForm() {
    //   this.$v.orderSelected.$touch();
    //   if (this.$v.orderSelected.$error) {
    //     return;
    //   } else {
    //     this.onSubmitedOrder();
    //   }
    // },
    onBackToOrderListPage() {
      window.location.href = `/?brandID=${this.$route.query.brandID}${
        this.$route.query.isMobile == "true" ? "&isMobile=true" : ""
      }`;
    },
    onSubmitFreeProduct(freeProduct, index) {
      this.orderSelected.freeProducts[index].products = freeProduct.products;
      this.onCalculatedPrice();
    },
    updateTrackingNo: async function(
      transactionId,
      TrackingNo,
      note,
      selectedUpdateCustomer
    ) {
      this.$store.dispatch("setIsLoading", true);
      let body = {};
      if (note) {
        body = {
          transactionId: transactionId,
          TrackingNo: TrackingNo,
          note: note,
          sendMessage: selectedUpdateCustomer
        };
      } else {
        body = {
          transactionId: transactionId,
          TrackingNo: TrackingNo,
          note: "",
          sendMessage: selectedUpdateCustomer
        };
      }

      await this.$axios
        .put(this.$baseUrl + "/api/transaction/updateTrackingNo", body)
        .then(response => {
          if (response.data.result === 1) {
            this.getOrderInformationById(this.orderSelected.id);
            this.setShowModalUpdateTracking(false);
            this.message = "อัพเดท tracking number สำเร็จ";
            this.$store.dispatch("setIsLoading", false);
            this.$refs.modalMessage.show();
            this.$store.dispatch("setReloadListFlag", true);
          } else {
            this.message = response.data.message;
            this.$store.dispatch("setIsLoading", false);
            this.$refs.modalMessage.show();
            this.$store.dispatch("setReloadListFlag", true);
          }
        });
    },
    setShowModalUpdateTracking(value) {
      this.isShowModalTracking = value;
      this.handleForcrrefresh();
    },
    onDownloadJobsheet() {
      this.$store.dispatch("setIsLoading", true);
      this.$axios
        .post(
          this.$baseUrl + "/api/transaction/export-shipping",
          [this.orderSelected.id],
          {
            responseType: "blob"
          }
        )
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]), {
            type: "application/octet-stream"
          });
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          if (this.orderSelected) {
            fileLink.setAttribute(
              "download",
              this.orderSelected.invoiceNo + "_Shipping_Label.pdf"
            );
            this.openPDFInBrowser(response);
          } else {
            fileLink.setAttribute(
              "download",
              this.currentDate + "_Shipping_Label.pdf"
            );
            this.openPDFInBrowser(response);
          }
          document.body.appendChild(fileLink);
          fileLink.click();
          this.getOrderInformationById(this.orderSelected.id);
          this.$store.dispatch("setReloadListFlag", true);
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(error => alert(error));
    },
    openPDFInBrowser(response) {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    },
    onSubmitedRestock(isRestock, reasonId, note) {
      this.$store.dispatch("setIsLoading", true);
      this.onChangeStatus(7, true, isRestock, reasonId, note);
    },
    setShowModalCancelOrder(value) {
      this.isShowModalCancel = value;
      this.handleForcrrefresh();
    },
    onChangeStatus: async function(id, isShowModal, isRestock, reasonId, note) {
      let body = {};
      if (note) {
        body = {
          orderId: this.orderSelected.id,
          statusId: id,
          reStock: isRestock,
          reasonId: reasonId,
          reasonNote: note
        };
      } else {
        body = {
          orderId: this.orderSelected.id,
          statusId: id,
          reStock: isRestock
        };
      }

      await this.$axios
        .post(this.$baseUrl + "/api/transaction/authorizedstatus", body)
        .then(response => {
          if (response.data.result === 1) {
            if (isShowModal === true) {
              if (id === 4) {
                this.message = "ยืนยันการชำระเงินสำเร็จ";
              } else if (id === 8) {
                this.message = "ปฏิเสธการยืนยันการชำระเงินสำเร็จ";
              } else if (id === 7) {
                this.setShowModalCancelOrder(false);
                this.message = "ยกเลิกคำสั่งซื้อสำเร็จ";
              }
              //this.$store.dispatch("setIsLoading", false);
              this.$refs.modalMessage.show();
            } else {
              this.urlLink = response.data.detail.urlLink;
              setTimeout(() => {
                //this.$store.dispatch("setIsLoading", false);
                this.$refs.modalCopyLink.show();
              }, 500);
            }

            this.getOrderInformationById(this.orderSelected.id);
            this.$store.dispatch("setReloadListFlag", true);
          } else {
            this.$store.dispatch("setIsLoading", false);
            this.message = response.data.message;
            this.$refs.modalMessage.show();
          }
        });
    },
    getFreeProducts() {
      let promotions = [];
      let freeProducts = JSON.parse(
        JSON.stringify(this.orderSelected.freeProducts)
      );

      freeProducts.forEach(element => {
        let promotionIndex = promotions.findIndex(el => {
          return el.promotionId == element.promotionId;
        });
        if (promotionIndex == -1) {
          promotions.push({
            promotionId: element.promotionId,
            promotionName: element.promotionName,
            products: element.products
          });
        } else {
          element.products.forEach(product => {
            let productIndex = promotions[promotionIndex].products.findIndex(
              el => {
                return el.productId == product.productId;
              }
            );
            if (productIndex == -1) {
              promotions[promotionIndex].products.push(product);
            } else {
              promotions[promotionIndex].products[productIndex].quantity +=
                product.quantity;
            }
          });
        }
      });

      this.freeProducts = promotions;
    },
    onSubmitedOrder() {
      this.$v.orderSelected.$touch();

      if (this.$v.orderSelected.$error) {
        return;
      } else {
        if (this.orderSelected.statusId == 7 && this.changeStatusFlag) {
          this.setShowModalCancelOrder(true);
        } else if (this.changeStatusFlag) {
          this.$store.dispatch("setIsLoading", true);
          this.onChangeStatus(
            this.orderSelected.statusId,
            false,
            this.isRestock
          );
        } else if (!this.changeStatusFlag) {
          if (this.orderSelected.statusId !== 1) {
            this.$store.dispatch("setIsLoading", true);
            this.onGetUrlLink(this.orderSelected.id);
          } else {
            this.$store.dispatch("setIsLoading", true);
            this.onCreateOrUpdateOrder();
          }
        }
      }
    },
    onGetUrlLink(orderId) {
      this.$store.dispatch("setIsLoading", true);
      this.$axios
        .get(this.$baseUrl + "/api/transaction/" + orderId + "/link")
        .then(response => {
          if (response.data.result === 1) {
            this.urlLink = response.data.detail.urlLink;
            setTimeout(() => {
              this.$store.dispatch("setIsLoading", false);
              this.$refs.modalCopyLink.show();
            }, 500);
          }
        });
    },
    onCreateOrUpdateOrder() {
      this.$store.dispatch("setIsLoading", true);
      let typeId = 0;
      if ([1, 2].includes(this.orderSelected.saleChannel)) {
        typeId = 2;
      } else if (this.orderSelected.saleChannel == 3) {
        typeId = 1;
      }
      this.$axios
        .post(this.$baseUrl + "/api/transaction/save", {
          cart: {
            brandId: this.orderSelected.brandId,
            branchId: this.orderSelected.branchId,
            id: this.orderSelected.id,
            products: this.orderSelected.products,
            typeId: typeId,
            ReferenceId: this.orderSelected.buyerInfo.userInfo
              .socialReferenceId,
            socialSalesChannel: this.orderSelected.saleChannel,
            freeProducts: this.orderSelected.freeProducts,
            productDiscount: this.orderSelected.productDiscount,
            promoCode: this.orderSelected.promoCode,
            isSeller: true
          },
          note: this.orderSelected.note
        })
        .then(response => {
          if (response.data.result === 1) {
            this.urlLink = response.data.detail.urlLink;
            this.remarkMessage = response.data.detail.message;
            this.$store.dispatch("setIsLoading", false);
            this.$store.dispatch("setReloadListFlag", true);
            this.$refs.modalCopyLink.show();
            if (this.orderSelected.id !== 0) {
              //this.getOrderInformationById(response.data.detail.id);
              this.sendUpdatedAddress(
                this.orderSelected.buyerInfo,
                response.data.detail.id
              );
            } else {
              this.sendUpdatedAddress(
                this.orderSelected.buyerInfo,
                response.data.detail.id
              );
              this.$router.push(
                `/${response.data.detail.id}?brandID=${
                  this.$route.query.brandID
                }${
                  this.$route.query.isMobile == "true" ? "&isMobile=true" : ""
                }`
              );
            }

            // this.setShowModalCopyLink(true);
          } else {
            this.$store.dispatch("setIsLoading", false);
            this.message = response.data.message;
            this.$refs.modalMessage.show();
          }
        });
    },
    addProductQuantity(product) {
      if (this.orderSelected.statusId === 1) {
        let indexTarget = this.orderSelected.products.findIndex(
          item => item.id === product.id
        );

        if (indexTarget > -1) {
          product.quantity =
            this.orderSelected.products[indexTarget].quantity + 1;
          this.orderSelected.products[indexTarget] = product;
        }
        if (this.isUseCoupon == false) {
          this.orderSelected.promoCode = "";
        }
        this.onCalculatedPrice();
      }
    },
    sendUpdatedAddress: async function(value, orderId) {
      await this.$axios
        .post(
          this.$baseUrl + "/api/transaction/" + orderId + "/buyerinfo",
          value
        )
        .then(response => {
          if (response.data.result === 1) {
            this.getOrderInformationById(orderId);
          }
        });
    },
    removeProductQuantity(product) {
      if (this.orderSelected.statusId === 1) {
        let indexTarget = this.orderSelected.products.findIndex(
          item => item.id === product.id
        );
        if (indexTarget > -1) {
          product.quantity =
            this.orderSelected.products[indexTarget].quantity - 1;
          this.orderSelected.products[indexTarget] = product;
        }
        if (this.isUseCoupon == false) {
          this.orderSelected.promoCode = "";
        }
        this.onCalculatedPrice();
      }
    },
    onCalculatedPrice() {
      let body = {
        brandId: this.orderSelected.brandId,
        branchId: this.orderSelected.branchId,
        products: this.orderSelected.products,
        freeProducts: this.orderSelected.freeProducts,
        productDiscount: this.orderSelected.productDiscount,
        promoCode: this.orderSelected.promoCode
      };
      this.$axios
        .post(this.$baseUrl + "/api/transaction/calculate", body)
        .then(async response => {
          this.orderSelected.products = response.data.detail.products;
          this.orderSelected.grandTotal = response.data.detail.grandTotal;
          this.orderSelected.shippingCost = response.data.detail.shippingCost;
          this.orderSelected.discount = response.data.detail.discount;
          this.orderSelected.promotions = response.data.detail.promotions;
          this.orderSelected.promoCode = response.data.detail.promoCode;
          this.orderSelected.promoCodeMessage =
            response.data.detail.promoCodeMessage;
          this.orderSelected.total = response.data.detail.total;
          this.orderSelected.freeProducts = response.data.detail.freeProducts;
          this.orderSelected.productDiscount =
            response.data.detail.productDiscount;
          this.orderSelected.discountShipping =
            response.data.detail.discountShipping;
          this.$nextTick(() => {
            if (this.$refs.freeProduct) {
              this.$refs.freeProduct.onCheckFreeProductDisableFlag();
            }
          });
          if (this.orderSelected.promoCode) {
            this.isUseCoupon = true;
          } else {
            this.isUseCoupon = false;
          }
          if (this.orderSelected.promoCodeMessage) {
            this.isUseCoupon = false;
            this.orderSelected.promoCode = "";
            this.message = this.orderSelected.promoCodeMessage;
            this.$refs.modalMessage.show();
          }
        });
    },
    setShowModalProducts(value) {
      if (this.orderSelected.statusId == 1) {
        if (this.isStore === 1) {
          if (this.orderSelected.branchId) {
            this.isShowModalProduct = value;
          } else {
            this.$refs.branch.focus();
          }
        } else {
          this.isShowModalProduct = value;
        }
      }
    },
    addProductToLists(product) {
      let filterProduct = this.orderSelected.products.some(
        item => item.id === product.id
      );
      if (filterProduct) {
        let indexTarget = this.orderSelected.products.findIndex(
          item => item.id === product.id
        );

        if (indexTarget > -1) {
          product.quantity =
            this.orderSelected.products[indexTarget].quantity + 1;
          this.orderSelected.products[indexTarget] = product;
        }
      } else {
        product.quantity = 1;
        this.orderSelected.products.push(product);
      }

      this.orderSelected.productDiscount.forEach(element => {
        element.products.forEach(pd => {
          if (pd.productId == product.id) {
            pd.selected = true;
          }
        });
      });

      this.onCalculatedPrice();
    },
    onSelectedStatus(id, name) {
      this.changeStatusFlag = true;
      this.orderSelected.statusId = id;
      this.orderSelected.statusName = name;
      this.handleForcrrefresh();
    },
    handleForcrrefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    },
    onSaleChannelSelected(saleChanel) {
      this.orderSelected.saleChannel = saleChanel;
      this.orderSelected.buyerInfo.userInfo.socialSalesChannelId = saleChanel;
    },
    onBranchSelected(branchId) {
      this.orderSelected.branchId = branchId;
    },
    getAllBranch() {
      this.$axios
        .get(
          this.$baseUrl + "/api/brand/" + this.orderSelected.brandId + "/branch"
        )
        .then(response => {
          if (response.data.result === 1) {
            this.branchLists = response.data.detail;

            if (this.branchLists.length === 1) {
              this.orderSelected.branchId = this.branchLists[0].id;
            }
          }
        });
    },
    getSocialMediaSalesChanel() {
      this.$axios
        .get(this.$baseUrl + "/api/socialmedia/salesChannel")
        .then(response => {
          if (response.data.result === 1) {
            this.saleChannelLists = [...response.data.detail];

            if (this.saleChannelLists.length === 1) {
              this.orderSelected.saleChannel = this.saleChannelLists[0].id;
            }
          } else {
            alert(response.data.message);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}
.panel-overflow {
  height: calc(100vh - 100px);
  overflow: auto;
  overflow-x: hidden;
}
.fixed-footer {
  margin: auto;
  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1030;
  display: block !important;
  background-color: #f8f9fa !important;
}

.custom-margin {
  margin-top: 100px;
}
.f-size-18 {
  font-size: 18px;
}
.card-box-header {
  background-color: #f8f9fa;
  padding: 0.4rem 1rem;
  position: relative;
  border-bottom: 1px solid #e6e7eb;
}
.img_back {
  position: relative;
  height: 44px;
  width: auto;
}
.img_back_icon {
  height: 100%;
  width: 15px;
}
@media (max-width: 767.98px) {
  .card-box-header {
    padding: 0.4rem 1.25rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
@media (max-width: 1112px) {
  .panel-overflow {
    height: calc(100vh - 50px);
    overflow: auto;
    overflow-x: hidden;
  }
  .fixed-footer {
    margin: auto;
    bottom: 0;
    position: fixed;
    min-width: 100%;
    right: 0;
    left: 0;
    z-index: 1030;
    display: block !important;
    background-color: #f8f9fa !important;
  }
}
</style>
