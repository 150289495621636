<template>
  <div>
    <b-modal
      v-model="isModalShow"
      title="ยกเลิกรายการสั่งซื้อ"
      @hide="setShowModalCancelOrder(false)"
    >
      <div>
        <b-row>
          <b-col>
            กรุณาเลือกเหตุผลในการยกเลิกคำสั่งซื้อ
            <small v-if="validate && resonSelected !== '0'" class="text-danger"
              >กรุณาระบุเหตุผลในการยกเลิก</small
            >
          </b-col>
        </b-row>
        <b-row class="ml-2 mt-2">
          <b-col>
            <b-form-radio-group
              v-model="resonSelected"
              :options="reasonLists"
              class="mb-3"
              value-field="id"
              text-field="name"
              stacked
              disabled-field="notEnabled"
            >
              <b-form-radio v-model="resonSelected" value="0"
                >อื่นๆ
                <small
                  v-if="validate && resonSelected === '0' && !orderNote"
                  class="text-danger"
                  >กรุณาระบุรายละเอียดเพิ่มเติม</small
                ></b-form-radio
              >
              <b-row v-if="resonSelected === '0'" class="ml-2 mt-2">
                <b-col>
                  <b-form-textarea
                    id="textarea-note"
                    v-model="orderNote"
                    placeholder="กรุณากรอกรายละเอียดเพิ่มเติม"
                  ></b-form-textarea>
                </b-col>
              </b-row>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row v-if="reStockFlag">
          <b-col> Restock สินค้า </b-col>
        </b-row>
        <b-row class="ml-2 mt-2" v-if="reStockFlag">
          <b-col>
            <b-form-select v-model="reStockSelected" class="mb-3">
              <b-form-select-option :value="false"
                >ไม่ต้องการ</b-form-select-option
              >
              <b-form-select-option :value="true">ต้องการ</b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            class="float-right bg-color-primary text-white"
            @click="onSubmitedRestock(reStockSelected)"
          >
            ตกลง
          </b-button>
          <b-button
            variant="secondary"
            class="float-right mr-2"
            @click="setShowModalCancelOrder(false)"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "CancelOrder",
  props: {
    isShow: {
      required: false,
      type: Boolean
    },
    note: String,
    orderId: Number
  },
  data() {
    return {
      isModalShow: this.isShow,
      resonSelected: "",
      orderNote: "",
      validate: false,
      reStockSelected: false,
      reasonLists: [],
      reStockFlag: false
    };
  },
  created: async function() {
    this.isModalShow = this.isShow;
    if (parseInt(this.orderId) !== 0) {
      await this.getCancelInfo();
    }
  },
  methods: {
    getCancelInfo() {
      this.$axios
        .get(this.$baseUrl + "/api/transaction/" + this.orderId + "/cancelInfo")
        .then(response => {
          if (response.data.result === 1) {
            this.reasonLists = response.data.detail.reasonList;
            this.reStockFlag = response.data.detail.canRestock;
          } else {
            alert(response.data.message);
          }
        });
    },
    setShowModalCancelOrder(value) {
      this.$emit("setShowModalCancelOrder", value);
    },
    validateReason() {
      if (!this.resonSelected) return false;
      if (this.resonSelected && this.resonSelected === "0") {
        if (!this.orderNote) return false;
      }
      return true;
    },
    onSubmitedRestock(isRestock) {
      this.validate = true;
      if (this.validateReason()) {
        if (this.resonSelected === "0") {
          this.$emit("onSubmitedRestock", isRestock, 0, this.orderNote);
        } else {
          var reasonName = this.reasonLists.find(
            item => item.id === this.resonSelected
          ).name;
          this.$emit(
            "onSubmitedRestock",
            isRestock,
            this.resonSelected,
            reasonName
          );
        }
        this.validate = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.product-panel {
  overflow: auto;
  max-height: 400px;
}
</style>
