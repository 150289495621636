<template>
  <div>
    <div
      class="mt-2"
      v-for="(free, index) in freeProductDisplay"
      :key="index + 'freepromotion'"
    >
      <div class="" style="color: black">
        <b-row class="mt-1">
          <b-col class="pl-2">
            <label for="" class="m-0 ml-2">
              โปรโมชั่น : {{ free.promotionName }}
            </label>
          </b-col>
          <b-col class="pl-2 d-flex justify-content-end" v-if="isEdit">
            <label for="" class="m-0 pointer">
              <u @click="onpenModalFreeProduct(free)">เลือกของแถม</u>
            </label>
          </b-col>
        </b-row>

        <template v-if="free.poductSelectedCount == 0">
          <div class="rounded shadow-sm p-5 bg-color-secondary my-1 d-flex">
            <div class="text-secondary m-auto pointer">กรุณาเลือกของแถม</div>
          </div>
        </template>
        <template v-if="free.poductSelectedCount !== 0">
          <b-row
            class="px-3"
            v-for="(freeitem, index) in free.products"
            :key="index + 'freepromotion'"
          >
            <b-col class="p-0">
              <FreeProductItem :freeproduct="freeitem" />
            </b-col>
          </b-row>
        </template>
      </div>
    </div>

    <ModalFreeProduct
      :selectedPromotion="selectedPromotion"
      v-on:onSubmitFreeProduct="onSubmitFreeProduct"
      ref="modalFreeProduct"
    />
  </div>
</template>

<script>
import FreeProductItem from "@/components/order/component/FreeProductItem";
import ModalFreeProduct from "@/views/Modals/ModalFreeProduct.vue";
export default {
  props: {
    freeProducts: Array,
    isEdit: Boolean
  },
  components: {
    FreeProductItem,
    ModalFreeProduct
  },
  data() {
    return {
      selectedPromotion: {
        limitProduct: 0,
        poductSelectedCount: 0,
        products: [],
        promotionId: 0,
        promotionIndex: 0,
        promotionName: "Prmotion GWP 2"
      }
    };
  },
  computed: {
    freeProductDisplay() {
      return JSON.parse(JSON.stringify(this.freeProducts));
    }
  },
  mounted() {
    this.onCheckFreeProductDisableFlag();
  },

  methods: {
    async onSubmitFreeProduct(freeProduct) {
      let freeProductsDisplayIndex = this.freeProductDisplay.findIndex(
        el => el.promotionId == freeProduct.promotionId
      );
      if (freeProductsDisplayIndex > -1) {
        this.freeProductDisplay[freeProductsDisplayIndex] = freeProduct;
        this.$emit(
          "onSubmitFreeProduct",
          freeProduct,
          freeProductsDisplayIndex
        );
        await this.onCheckFreeProductDisableFlag();
      }
    },
    onpenModalFreeProduct(selectedPromotion) {
      this.selectedPromotion = selectedPromotion;
      this.$nextTick(() => {
        this.$refs.modalFreeProduct.show();
      });
    },
    onCheckFreeProductDisableFlag() {
      let countFlag = 0;
      this.freeProductDisplay.forEach(promotion => {
        let freeProductSelectedCount = 0;
        promotion.poductSelectedCount = 0;
        promotion.products.forEach(product => {
          if (product.selected) {
            freeProductSelectedCount =
              freeProductSelectedCount + product.quantity;
            promotion.poductSelectedCount = freeProductSelectedCount;
          }
        });

        if (freeProductSelectedCount < promotion.limitProduct) {
          countFlag = countFlag + 1;
        }
      });

      if (countFlag > 0) {
        this.$store.dispatch("setIsDisableSubmitButton", true);
      } else {
        this.$store.dispatch("setIsDisableSubmitButton", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
