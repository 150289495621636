<template>
  <b-modal
    v-model="isModalShow"
    title="ข้อมูลลูกค้า"
    hide-footer
    scrollable
    @hide="setShowModalAddress(false, false)"
  >
    <div>
      <b-row>
        <b-col>
          <AddressUserForm
            :form="orderSelected.buyerInfo.userInfo"
            :v="v.orderSelected.buyerInfo.userInfo"
            :getUserInfoByTelephone="getUserInfoByTelephone"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="mb-2 textcolor">ที่อยู่ในการจัดส่ง</div>
        </b-col>
      </b-row>
      <AddressForm
        :form="orderSelected.buyerInfo.shippingAddress"
        :v="v.orderSelected.buyerInfo.shippingAddress"
        :type="'shipping'"
        :handleAddress="handleShippingAddress"
        :data="orderSelected"
      />

      <div class="d-flex mt-2">
        <div class="form-check">
          <input
            v-model="data.buyerInfo.userInfo.isRequestTaxInvoice"
            type="checkbox"
            class="form-check-input"
            id="getTaxReceiptCheckbox"
          />
          <label class="form-check-label textcolor" for="getTaxReceiptCheckbox"
            >ออกใบกำกับภาษี</label
          >
        </div>
      </div>
      <div class="d-flex mt-2">
        <div class="form-check">
          <input
            v-model="data.buyerInfo.sameAddress"
            @change="
              handleShippingAddress(
                data.buyerInfo.sameAddress,
                data.buyerInfo.shippingAddress
              )
            "
            type="checkbox"
            class="form-check-input"
            id="sameReceiptCheckbox"
          />
          <label class="form-check-label textcolor" for="sameReceiptCheckbox"
            >ใช้ที่อยู่เดียวกับที่อยู่ในการจัดส่ง</label
          >
        </div>
      </div>
      <b-collapse
        :visible="
          !data.buyerInfo.sameAddress ||
            data.buyerInfo.userInfo.isRequestTaxInvoice ||
            (data.buyerInfo.sameAddress &&
              data.buyerInfo.userInfo.isRequestTaxInvoice)
        "
        class="border-0"
      >
        <div class="my-3 textcolor">ที่อยู่ในการออกใบกำกับภาษี</div>
        <AddressForm
          :form="orderSelected.buyerInfo.billingAddress"
          :v="v.orderSelected.buyerInfo.billingAddress"
          :type="'billing'"
          :handleAddress="handleBillingAddress"
          :data="orderSelected"
        />
      </b-collapse>
    </div>
    <div>
      <b-row>
        <b-col>
          <b-button
            v-if="orderStatus === 5 || orderStatus === 7"
            pill
            class="btn bg-dark my-2 text-white w-100"
            @click="setShowModalAddress(false)"
            >ปิด</b-button
          >
          <b-button
            v-else
            pill
            class="btn bg-color-primary my-2 text-white w-100"
            @click="onUpdatedAddress"
            >แก้ไขข้อมูล</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import AddressForm from "@/components/order/component/OrderAddressDetailForm";
import AddressUserForm from "@/components/order/component/OrderUserDetailForm";
export default {
  name: "ModalAddressDetail",
  components: {
    AddressForm,
    AddressUserForm
  },
  props: {
    orderSelected: Object,
    isShow: {
      required: false,
      type: Boolean
    },
    orderStatus: {
      required: false,
      type: Number
    },
    v: {
      required: true,
      type: Object
    },
    onUpdatedAddress: Function
  },
  data() {
    return {
      data: this.orderSelected,
      isModalShow: false
    };
  },
  created() {
    this.data = this.orderSelected;
    this.isModalShow = this.isShow;
  },
  computed: {
    ...mapGetters({
      theOneCardFlag: "getUseTheOneCardFlag"
    })
  },
  methods: {
    setShowModalAddress() {
      this.$emit("setShowModalAddress");
    },
    handleShippingAddress(val, address) {
      if (val) {
        this.data.buyerInfo.sameAddress = val;
      }
      if (this.data.buyerInfo.sameAddress) {
        this.data.buyerInfo.billingAddress.firstname = address.firstname;
        this.data.buyerInfo.billingAddress.lastname = address.lastname;
        this.data.buyerInfo.billingAddress.address = address.address;
        this.data.buyerInfo.billingAddress.road = address.road;
        this.data.buyerInfo.billingAddress.subdistrict = address.subdistrict;
        this.data.buyerInfo.billingAddress.district = address.district;
        this.data.buyerInfo.billingAddress.province = address.province;
        this.data.buyerInfo.billingAddress.zipcode = address.zipcode;
        this.data.buyerInfo.billingAddress.zipcodeExternalId =
          address.zipcodeExternalId;
      }
    },
    handleBillingAddress() {
      this.data.buyerInfo.sameAddress = false;
    },
    getUserInfoByTelephone() {
      const tel = this.data.buyerInfo.userInfo.telephone;

      if (tel && tel.length === 10) {
        this.$axios
          .post(
            this.$baseUrl +
              "/api/user/" +
              tel +
              "/" +
              this.data.buyerInfo.userInfo.socialSourceId
          )
          .then(response => {
            if (response.data.result === 1) {
              this.data.buyerInfo.billingAddress =
                response.data.detail.billingAddress;
              this.data.buyerInfo.shippingAddress =
                response.data.detail.shippingAddress;
              this.data.buyerInfo.userInfo = response.data.detail.userInfo;
              this.data.buyerInfo.isConsent = response.data.detail.isConsent;
              this.data.buyerInfo.sameAddress =
                response.data.detail.sameAddress;
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.name-title {
  border: none;
  border-bottom: 2px solid #ebebeb;
  outline: none;
  box-shadow: none;
}
.textcolor {
  color: #575757;
}
</style>
