<template>
  <div>
    <div class="panel-address">
      <b-row>
        <b-col md="6">
          <InputText
            v-model="form.telephone"
            textFloat="หมายเลขโทรศัพท์"
            placeholder="หมายเลขโทรศัพท์"
            type="text"
            name="telephone"
            :maxlength="10"
            size="lg"
            isRequired
            @onkeypress="isNumber($event)"
            @onkeyup="getUserInfoByTelephone"
            :isValidate="v.telephone.$error"
            :v="v.telephone"
            class="f-regular"
          />
        </b-col>
        <b-col md="6">
          <InputText
            v-model="form.firstname"
            textFloat="ชื่อ"
            placeholder="ชื่อ"
            type="text"
            name="nameOrCompanyName"
            size="lg"
            isRequired
            :isValidate="v.firstname.$error"
            :v="v.firstname"
            class="f-regular"
          />
        </b-col>
        <b-col md="6">
          <InputText
            v-model="form.lastname"
            textFloat="นามสกุล"
            placeholder="นามสกุล"
            type="text"
            name="lastname"
            size="lg"
            isRequired
            :isValidate="v.lastname.$error"
            :v="v.lastname"
            class="f-regular"
          />
        </b-col>

        <b-col class="col-md-6 col-12">
          <InputText
            type="text"
            textFloat="อีเมล์"
            placeholder="อีเมล์"
            v-model="form.email"
            size="lg"
            class="f-regular"
            :isValidate="v.email.$error"
            :v="v.email"
          />
        </b-col>
        <b-col class="col-md-12 col-12" v-if="theOneCardFlag">
          <InputText
            type="text"
            textFloat="The 1 Card (หมายเลขบัตร, หมายเลขโทรศัพท์)"
            placeholder="The 1 Card (หมายเลขบัตร, หมายเลขโทรศัพท์)"
            v-model="form.theOneCardRef"
            size="lg"
            class="f-regular"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";
export default {
  name: "UserProfileAddressFormInput",
  components: {
    InputText
  },
  props: {
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    getUserInfoByTelephone: {
      required: true,
      type: Function
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      theOneCardFlag: "getUseTheOneCardFlag"
    })
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  created: async function() {}
};
</script>
