<template>
  <div>
    <b-dropdown right class="dropdown-status">
      <template v-slot:button-content>
        <div
          class="m-auto p-2 px-3 bg-color-primary text-white rounded-pill w-100"
          :style="{ backgroundColor: mainColor }"
        >
          <span>{{ orderSelected.statusName }}</span>
        </div>
      </template>
      <div v-for="(item, index) in statuses" :key="index + 'status'">
        <b-dropdown-item
          @click="onClickedStatus(item)"
          :class="{
            'selected-background': item.id === orderSelected.statusId
          }"
          v-if="index !== 0"
        >
          <div class="d-flex">
            <div
              class="
                circle-status
                d-table-cell
                align-middle
                rounded-circle
                text-white text-center
                m-auto
              "
              :style="{ backgroundColor: `${background(item.id)}` }"
            >
              <div class="margin-custom-2">
                <span v-if="index > 6">X</span>
                <span v-else>{{ index }}</span>
              </div>
            </div>
            <div class="m-auto">
              <span class="mx-1">{{ item.name }}</span>
            </div>
          </div>
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    orderSelected: Object,
    onSelectedStatus: Function
  },
  data() {
    return {
      statuses: [],
      changableStatus: []
    };
  },
  created: async function() {
    await this.getAllStatus();
    await this.getChanngAbleStatus();
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      mainColor: "getBrandColor"
    })
  },
  methods: {
    onClickedStatus(item) {
      if (
        this.changableStatus.some(status => status.id === item.id) &&
        item.id !== this.orderSelected.statusId
      ) {
        this.onSelectedStatus(item.id, item.name);
      }
    },
    background(id) {
      return this.changableStatus.some(el => el.id === id)
        ? "#8F20C6"
        : "#f1f1f1";
    },

    getChanngAbleStatus: async function() {
      await this.$axios
        .get(
          this.$baseUrl +
            "/api/transaction/" +
            this.orderSelected.id +
            "/authorizedstatus"
        )
        .then(response => {
          if (response.data.result === 1) {
            this.changableStatus = [...response.data.detail];
            if (
              response.data.detail.length == 0 &&
              this.orderSelected.statusId == 1
            ) {
              this.changableStatus = [];
              this.changableStatus = [{ id: 1, name: "สั่งซื้อ" }];
            }
          } else {
            console.log(response.data.message);
          }
        });
    },
    getAllStatus: async function() {
      await this.$axios
        .get(this.$baseUrl + "/api/filter/status")
        .then(response => {
          if (response.data.result === 1) {
            this.statuses = response.data.detail;
          } else {
            alert(response.data.message);
          }
        });
    },
    onHandleStatusChange(id) {
      this.$emit("onHandleStatusChange", id);
    }
  }
};
</script>

<style lang="scss">
.circle-status {
  height: 30px;
  width: 30px;
  background-color: #f1f1f1;
  font-size: 16px;
}
.margin-custom-2 {
  margin: 2px;
}
.selectWrapper {
  border-radius: 0 50px 50px 0;
  display: inline-block;
  overflow: hidden;
  border: 1px solid #cccccc;
}
.dropdown-status > .dropdown-item.active,
.dropdown-status > .dropdown-item:active {
  background-color: #cccccc;
}

.dropdown-status > .dropdown-toggle {
  background-color: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none !important;
}
.dropdown-status > .dropdown-toggle::after {
  display: none !important;
}
// .dropdown-item:hover,
// .dropdown-item:focus {
//   background-color: transparent !important;
// }
.dropdown-status > .dropdown-menu {
  width: 280px;
  z-index: 9999;
}
</style>
