<template>
  <b-modal
    v-model="isModalShow"
    title="ค้นหาสินค้า"
    hide-footer
    @hide="setShowModalProducts(false)"
  >
    <div class="product-panel">
      <b-input-group>
        <b-form-input
          ref="keywordInput"
          v-model="filter.search"
          @keyup.enter="onSearchProduct()"
          placeholder="ค้นหาโดยชื่อสินค้า, รหัสบาร์โค้ด, SKU"
          style="border-radius: 50px 0 0 50px"
        ></b-form-input>
        <template v-slot:append>
          <button
            class="btn bg-dark text-white pill-right"
            @click="getProducts(false)"
          >
            ค้นหา
          </button>
        </template>
      </b-input-group>
      <div>
        <div class="product-panel" v-if="products">
          <div
            v-if="products.length === 0"
            class="text-color-secondary text-center m-3"
          >
            ไม่พบสินค้า
          </div>
          <div v-if="products.length >= 0" id="product-panel" class="my-5">
            <div v-for="(item, index) in products" :key="index" class="my-2">
              <div class="p-3 rounded shadow-s bg-color-secondary h-110">
                <div class="d-flex">
                  <div class="mr-2" v-if="item.imageUrl">
                    <img :src="item.imageUrl" class="product-image" />
                  </div>
                  <div
                    class="w-100 d-flex flex-column"
                    :class="{ 'h-80': !item.imageUrl }"
                  >
                    <div class="d-flex">
                      <div class="line-clamp mr-1">{{ item.name }}</div>
                      <div class="ml-auto">
                        <button
                          :disabled="item.availableQuantity <= 0"
                          @click="onSelectedProduct(item)"
                          class="button rounded-pill"
                          style="
                            width: 63px;
                            height: 30px;
                            border: 1px solid #575757;
                            background-color: transparent;
                          "
                        >
                          เพิ่ม
                        </button>
                      </div>
                    </div>
                    <div>
                      <div class="line-clamp">{{ item.barcode }}</div>
                    </div>
                    <div class="d-flex" v-if="item.productDiscountType == 0">
                      <div class="ml-auto">
                        <del v-if="item.promotionPrice !== 0">{{
                          item.price | numeral("0,0.00")
                        }}</del>
                      </div>
                    </div>
                    <div class="d-flex mt-auto">
                      <div class="line-clamp">
                        คงเหลือ {{ item.availableQuantity }} ชิ้น
                      </div>
                      <div class="ml-auto">
                        <div v-if="item.promotionPrice !== 0">
                          <div>
                            ราคา {{ item.promotionPrice | numeral("0,0.00") }}
                          </div>
                          <div class="text-right">
                            <del class="text-secondary">
                              {{ item.price | numeral("0,0.00") }}
                            </del>
                          </div>
                        </div>
                        <div v-else>
                          ราคา {{ item.price | numeral("0,0.00") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <infinite-loading
              spinner="spiral"
              @infinite="scrollMethod"
              ref="infiniteLoading"
            >
              <div slot="spinner" class="">
                <img
                  src="@/assets/images/loading.svg"
                  alt="loading"
                  class="loading-icon"
                />
              </div>
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
          <!-- <div
          id="product-panel"
          class="product-panel"
          @scroll="scrollMethod"
          v-if="products.length >= 0"
        >
          <div v-for="(item, index) in products" :key="index" class="my-2">
            <div class="p-3 rounded shadow-s bg-color-secondary">
              <b-row v-if="item.imageUrl !== ''">
                <b-col
                  cols="2"
                  xl="3"
                  md="3"
                  class="align-self-center d-none d-sm-block"
                >
                  <img style="width:100%" :src="item.imageUrl" alt />
                </b-col>
                <b-col cols="12" xl="9" md="9">
                  <b-row>
                    <b-col
                      cols="2"
                      xl="2"
                      class="align-self-center pt-1 pr-1 d-block d-sm-none"
                    >
                      <img style="width:100%" :src="item.imageUrl" alt />
                    </b-col>

                    <b-col cols="7" xl="9" md="8" class="pr-0 pl-1">
                      <div>{{ item.name }}</div>
                    </b-col>
                    <b-col
                      cols="3"
                      xl="3"
                      md="4"
                      class="d-flex justify-content-end"
                    >
                      <button
                        :disabled="item.availableQuantity <= 0"
                        @click="onSelectedProduct(item)"
                        class="button rounded-pill"
                        style="width:63px; height:30px;border:1px solid #575757;background-color:transparent"
                      >
                        เพิ่ม
                      </button>
                    </b-col>
                  </b-row>
                  <b-row
                    :class="{
                      'text-color-secondary': item.availableQuantity <= 0,
                      'text-color-grey': item.availableQuantity > 0,
                      'mt-1': true
                    }"
                  >
                    <b-col cols="6" xl="9" md="8" class="d-block d-sm-none">
                      <div>{{ item.barcode }}</div>
                    </b-col>
                    <b-col
                      cols="6"
                      xl="9"
                      md="8"
                      class="d-none d-sm-block pl-1"
                    >
                      <div>{{ item.barcode }}</div>
                    </b-col>
                    <b-col
                      cols="6"
                      xl="3"
                      md="4"
                      class="d-flex justify-content-end"
                      v-if="item.productDiscountType == 0"
                    >
                      <del v-if="item.promotionPrice !== 0">{{
                        item.price | numeral("0,0.00")
                      }}</del>
                    </b-col>
                  </b-row>
                  <b-row
                    :class="{
                      'text-color-secondary': item.availableQuantity <= 0,
                      'text-color-grey': item.availableQuantity > 0
                    }"
                  >
                    <b-col
                      cols="5"
                      xl="6"
                      md="6"
                      class="pr-0 d-block d-sm-none"
                    >
                      <div>คงเหลือ {{ item.availableQuantity }} ชิ้น</div>
                    </b-col>
                    <b-col
                      cols="5"
                      xl="6"
                      md="6"
                      class="pr-0 d-none d-sm-block pl-1"
                    >
                      <div>คงเหลือ {{ item.availableQuantity }} ชิ้น</div>
                    </b-col>

                    <b-col
                      cols="7"
                      xl="6"
                      md="6"
                      class="d-flex justify-content-end"
                      v-if="item.productDiscountType == 0"
                    >
                      <div v-if="item.promotionPrice !== 0">
                        ราคา {{ item.promotionPrice | numeral("0,0.00") }}
                      </div>
                      <div v-else>
                        ราคา {{ item.price | numeral("0,0.00") }}
                      </div>
                    </b-col>
                    <b-col
                      cols="7"
                      xl="6"
                      md="6"
                      class="d-flex justify-content-end"
                      v-if="item.productDiscountType !== 0"
                    >
                      <del v-if="item.promotionPrice !== 0">{{
                        item.price | numeral("0,0.00")
                      }}</del>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-else class="ml-1">
                <b-col cols="12" xl="12" md="12">
                  <b-row>
                    <b-col cols="9" xl="9" md="8" class="pr-0 pl-0">
                      <div>{{ item.name }}</div>
                    </b-col>
                    <b-col
                      cols="3"
                      xl="3"
                      md="4"
                      class="d-flex justify-content-end"
                    >
                      <button
                        :disabled="item.availableQuantity <= 0"
                        @click="onSelectedProduct(item)"
                        class="button rounded-pill"
                        style="width:63px; height:30px;border:1px solid #575757;background-color:transparent"
                      >
                        เพิ่ม
                      </button>
                    </b-col>
                  </b-row>
                  <b-row
                    :class="{
                      'text-color-secondary': item.availableQuantity <= 0,
                      'text-color-grey': item.availableQuantity > 0,
                      'mt-1': true
                    }"
                  >
                    <b-col cols="6" xl="9" md="8" class="pl-0">
                      <div>{{ item.barcode }}</div>
                    </b-col>
                    <b-col
                      cols="6"
                      xl="3"
                      md="4"
                      class="d-flex justify-content-end"
                    >
                      <del v-if="item.promotionPrice !== 0">{{
                        item.price | numeral("0,0.00")
                      }}</del>
                    </b-col>
                  </b-row>
                  <b-row
                    :class="{
                      'text-color-secondary': item.availableQuantity <= 0,
                      'text-color-grey': item.availableQuantity > 0
                    }"
                  >
                    <b-col cols="5" xl="6" md="6" class="pr-0 pl-0">
                      <div>คงเหลือ {{ item.availableQuantity }} ชิ้น</div>
                    </b-col>
                    <b-col
                      cols="7"
                      xl="6"
                      md="6"
                      class="d-flex justify-content-end"
                    >
                      <div v-if="item.promotionPrice !== 0">
                        ราคา {{ item.promotionPrice | numeral("0,0.00") }}
                      </div>
                      <div v-else>
                        ราคา {{ item.price | numeral("0,0.00") }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </div> -->
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AddProduct",
  props: {
    isShow: {
      required: false,
      type: Boolean
    },
    brandSelected: [String, Number],
    branchSelected: [String, Number],
    addProductToLists: Function,
    productDiscounts: Array
  },
  components: {},

  data() {
    return {
      filter: {
        page: 1,
        search: "",
        take: 10,
        brandId: 0,
        branchId: 0
      },
      products: null,
      forceRefresh: true,
      busy: false,
      loadFlag: false
    };
  },
  computed: {
    isModalShow: {
      get: function() {
        return this.isShow;
      },
      set: function() {}
    },
    branchId: {
      get: function() {
        return this.branchSelected;
      },
      set: function() {}
    },
    brandId: {
      get: function() {
        return this.brandSelected;
      },
      set: function() {}
    }
  },
  methods: {
    onSearchProduct() {
      this.getProducts(false);
    },
    handleForcefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    },
    getProducts: async function(scrollFlag, $state) {
      if (!scrollFlag) this.filter.page = 1;
      this.filter.branchId = this.branchId;
      this.filter.brandId = this.brandId;
      await this.$axios
        .get(this.$baseUrl + "/api/product", { params: this.filter })
        .then(response => {
          if (scrollFlag) {
            if (response.data.detail.detail.length == 0) {
              if ($state) $state.complete();
            } else {
              this.products = [
                ...this.products,
                ...response.data.detail.detail
              ];

              if ($state) $state.loaded();
            }
          } else {
            this.products = response.data.detail.detail;

            if ($state) $state.loaded();
          }

          // if (scrollFlag) {
          //   this.products = [...this.products, ...response.data.detail.detail];
          //   this.loadFlag = false;
          // } else {
          //   this.products = response.data.detail.detail;
          //   this.loadFlag = true;
          // }
          // this.products = this.products.map(pd => {
          //   return { ...pd, productDiscountType: 0, minimumPrice: 0 };
          // });

          // if (this.productDiscounts.length !== 0) {
          //   this.productDiscounts.forEach(element => {
          //     element.products.forEach(elp => {
          //       this.products.forEach(product => {
          //         // console.log(product);
          //         if (elp.productId === product.id) {
          //           product.productDiscountType = elp.minimumTypeId;
          //           product.minimumPrice = elp.minimumPrice;

          //           if (elp.minimumTypeId == 2) {
          //             product.promotionPrice =
          //               product.price -
          //               (product.price * elp.minimumPrice) / 100;
          //           } else if (elp.minimumTypeId == 1) {
          //             product.promotionPrice = product.price - elp.minimumPrice;
          //           }
          //         }
          //         this.handleForcefresh();
          //       });
          //     });
          //   });
          // }
        });
    },
    addCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00";
    },
    setShowModalProducts(value) {
      this.filter.search = "";
      this.products = {};
      this.$emit("setShowModalProducts", value);
    },
    onSelectedProduct(product) {
      product.quantity = 0;
      this.addProductToLists(product);
      this.setShowModalProducts(false);
    },
    scrollMethod($state) {
      setTimeout(() => {
        this.filter.page++;
        this.getProducts(true, $state);
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.h-80 {
  height: 80px;
}
.product-panel {
  overflow: auto;
  max-height: calc(100vh - 200px);
}
.product-image {
  height: 80px;
  width: 80px;
  object-fit: contain;
  border: 1px solid #e8e7e7;
  background-color: white;
}
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
